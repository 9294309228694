import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useCallback, useEffect, useRef, useState } from 'react';
/* import { InstagramEmbed, TikTokEmbed } from 'react-social-media-embed'; */
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';
import Button from '../Utils/Button.jsx';


export default function Social() {

	const t = useTranslation(lang);

	const [embeds, setEmbeds] = useState([]);

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
	}, []);

	const startAutoplay = () => {
		/* sliderRef.current.swiper.autoplay = {
			delay: 2500,
			disableOnInteraction: false,
		} */
	};

	useEffect(() => {
		fetch('/embeds.json')
			.then(response => response.json())
			.then(response => {
				setEmbeds(response);
				startAutoplay();
			});
	}, []);

	const breakpoints = {
		800: {
			slidesPerView: 1,
			spaceBetween: 20
		},
		900: {
			slidesPerView: 2,
			spaceBetween: 30
		},
		1280: {
			slidesPerView: 3,
			spaceBetween: 40
		},
		2560: {
			slidesPerView: 4,
			spaceBetween: 40
		}
	}

	return (
		<section className="container flex flex-col items-center w-full px-4 mx-auto mb-64 text-white max-w-screen-2xl">
			<h2 className="mx-auto mt-12 text-center text-white text-lg sm:text-xl text-shadow calibri" dangerouslySetInnerHTML={{ __html: t.its_been_a_while }}>
			</h2>

			<h2 className="w-full pt-8 mt-8 font-bold text-center uppercase text-xl sm:text-3xl text-shadow calibri-bold" dangerouslySetInnerHTML={{ __html: t.fan_is_within }}></h2>
			<img className="m-auto sm:w-1/2" src="/line 4.svg" alt="line" />

			<p className="w-full pb-8 text-lg text-center sm:text-xl text-shadow calibri" dangerouslySetInnerHTML={{ __html: t.more_than_voice }}></p>
			<p className="w-full pb-8 text-lg text-center sm:text-xl text-shadow calibri" dangerouslySetInnerHTML={{ __html: t.use_the_hashtag }}></p>


			<a href="/nyeremenyjatek">
				<Button theme="primary" classes="hover:brigh">
					<span className="pt-1 uppercase calibri-bold">{t.make_a_pledge}</span>
					<svg className="ml-2" width="8" height="13" viewBox="0 0 16 26" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M1.92845 23.6106L12.9951 13.0973L1.92845 2.58392" stroke="white" stroke-width="3.68894" stroke-linecap="round"/>
					</svg>
				</Button>
			</a>
			{/*

			<a className="bg-[#ACCC59] w-fit rounded-xl p-4 px-5 calibri-bold mx-auto flex flex-row items-center justifiy-evenly" href="/nyeremenyjatek">
				<div className="w-full align-text-bottom m-auto text-lg md:text-xl text-center uppercase" dangerouslySetInnerHTML={{ __html: t.make_a_pledge }}>
				</div>
				<img className="m-auto -rotate-90" src="/fb_prize/jatek idopontjai gomb nyil.svg" />
			</a>

			*/}

			<h2 className="w-full pt-8 mt-8 text-xl font-bold text-center uppercase sm:text-3xl text-shadow calibri-bold" dangerouslySetInnerHTML={{ __html: t.they_expressed_support }}></h2>
			<img className="w-full m-auto" src="/line 5.svg" alt="line" />

			<div className="relative w-full mt-8 swiper-container max-w-[80%] 2xl:max-w-screen-xl">
				<Swiper
					ref={sliderRef}
					spaceBetween={10}
					direction="horizontal"
					mousewheel={true}
					loop={true}
					speed={800}
					slidesPerView={'auto'}
					breakpoints={breakpoints}
					centeredSlides={true}
				>
					{embeds.map((embedUrl, slideIndex) => {
						return (
							<SwiperSlide key={slideIndex}>
								<div className="flex justify-center select-none scale-80 md:scale-100">
									{embedUrl.includes('instagram') ? (
										<div className="overflow-hidden bg-white border border-white rounded-2xl">
											{/* <InstagramEmbed url={embedUrl} width={'324px'} style={{minHeight: '650px'}} scrolling="no"></InstagramEmbed> */}
											<iframe src={embedUrl} scrolling="no" width="400px" style={{ minHeight: '730px' }} frameBorder="0" title={embedUrl}></iframe>
										</div>
									) : (
										<div className="flex justify-center overflow-hidden bg-white border border-white rounded-2xl">
											{/* <TikTokEmbed url={embedUrl} width={'324px'} style={{minHeight: '650px'}} scrolling="no"></TikTokEmbed> */}
											<iframe src={embedUrl} scrolling="no" width="400px" style={{ minHeight: '730px' }} frameBorder="0" title={embedUrl}></iframe>
										</div>
									)}
								</div>
							</SwiperSlide>
						)
					})}
				</Swiper>


				{/* Left */}
				<button className={`absolute left-[-4rem] z-10 px-6 py-4 h-full top-0 hover:scale-125 transition-all duration-300 ${embeds.length > 1 ? '' : 'hidden'}`} onClick={handlePrev}>
					<svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M16 1L2 15L16 29" stroke="white" strokeWidth="2" strokeLinecap="round" />
					</svg>
				</button>

				{/* Right */}
				<button className={`absolute right-[-4rem] z-10 px-6 py-4 h-full top-0 hover:scale-125 transition-all duration-300 ${embeds.length > 1 ? '' : 'hidden'}`} onClick={handleNext}>
					<svg width="17" height="30" viewBox="0 0 17 30" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M0.999998 29L15 15L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
					</svg>
				</button>
			</div>
		</section>
	)
}
