import React, { Fragment, useTransition } from "react";
import Winners from './Winners.jsx';
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';
import Button from '../Utils/Button';

function TiktokPrize() {

	const dates = [
		"2024.07.03. 11:00:00"
	];

	const t = useTranslation(lang);

	return (
		<>

			<div className="container max-w-screen-xl px-10 mx-auto">

				{/* Headline */}
				<h2 className="w-full mt-20 text-xl sm:text-3xl font-bold text-center uppercase text-shadow calibri-bold">{t.tiktok_headline}</h2>

				{/* Line */}
				<img className="mx-auto" src="/line 4.svg" alt="line" />

				{/* Paragraph */}
				<h1 className="w-full my-3 text-center text-lg sm:text-2xl font-bold text-shadow calibri-bold uppercase">{t.tiktok_paragraph}</h1>

				{/* Glowing rectangle */}
				<div className="mx-auto bg-transparent-white backdrop-blur-md border-2 md:border-4 border-solid rounded-2xl border-[#00FFAE] shadow-around shadow-[#00FFAE] mt-12">

					<div className="flex flex-col gap-4 p-8 md:p-10 lg:flex-row">

						{/* PS5 Image */}
						<div className="flex items-center justify-center image">
							<img className="w-3/4 sm:w-1/2 lg:w-fit max-w-[400px]" src="/tiktok_prize/SONY-PlayStation-5-Digital-Edition-transformed.png" alt="PS5" />
						</div>


						{/* Content */}
						<div className="flex flex-col items-center justify-center px-0 py-8 lg:px-8 lg:py-0 content">

							{/* Description */}
							<p className="text-lg text-center lg:text-2xl" dangerouslySetInnerHTML={{ __html: t.tiktok_description }}></p>

							{/* Period */}
							<p className="mt-8 text-lg text-center lg:text-2xl" dangerouslySetInnerHTML={{ __html: t.tiktok_period }}></p>

							{/* Buttons */}
							<div className="flex flex-col items-center justify-between w-full gap-4 px-0 mt-12 lg:px-4 lg:flex-row">

								<a target="_blank" href="/tiktok_prize/docs/MOL_TT_Fogadalom_Játékszabályzat.pdf" rel="noreferrer">
									<Button theme="secondary">
										<span className="text-base lg:text-xl pt-1 uppercase calibri-bold">{t.game_rules}</span>
									</Button>
								</a>

								<a target="_blank" href="https://www.tiktok.com/@mol.magyarorszag" rel="noreferrer">
									<Button theme="primary">
										<span className="text-base lg:text-xl pt-1 uppercase calibri-bold">{t.play}</span>
									</Button>
								</a>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Winners contestName="tiktok" dates={dates}></Winners>

		</>
	);
}

export default TiktokPrize;
