import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../localisation.js';

export default function Footer() {

	const t = useTranslation(lang);

	return (
		<footer className="bg-primary">
			<div className="flex flex-col justify-center px-4 mx-10 lg:justify-between lg:flex-row">

				{/* Logo, all rights reserved */}
				<div className="flex items-center justify-center py-4 text-white lg:justify-start">
					<img src="/logo-white.svg" alt="MOL Logo White"></img>
					<span className="pl-6 calibri-light">{t.mol_hungary_all_rights_reserved}</span>
				</div>

				{/* Cookie, Privacy */}
				<div className="flex items-center justify-center py-4 text-white lg:justify-evenly">
					<a className="pr-6 text-sm text-center border-r-2 md:ml-6 md:text-md calibri-light" target="_blank" href="#">{t.cookie_informant}</a>
					<a className="pr-6 ml-6 text-sm text-center border-r-2 md:text-md calibri-light" target="_blank" href="https://mol.hu/hu/felhasznalasi-feltetelek">{t.terms_of_use}</a>
					<a className="ml-6 text-sm text-center md:text-md calibri-light" target="_blank" href="https://mol.hu/hu/adatvedelem">{t.data_protection}</a>
				</div>
			</div>
		</footer>
	)
}
