import TiktokPrize from './Contest/TiktokPrize.jsx';
import InstagramPrize from './Contest/InstagramPrize.jsx';
import FacebookPrize from './Contest/FacebookPrize.jsx';
import Winners from './Contest/Winners.jsx';
import React, { Fragment } from "react";
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../localisation.js';

function Contest() {

	const t = useTranslation(lang);

	return (
		<Fragment>
			<div className="relative overflow-hidden bg-black app">
				{/* Background */}
				<div className="absolute left-0 w-screen h-full bg-no-repeat bg-cover top-[14rem] sm:top-[18rem] lg:top-[24rem] 2xl:top-[30rem]" style={{ backgroundImage: 'url("/contest_bg 3-low.jpg")' }}></div>
				<img src="/gradiens.svg" className="absolute bottom-0 left-0 z-10 w-screen h-auto bg-no-repeat bg-cover" />

				<div className="container relative z-10 mx-auto content">

					<div className="m-auto mt-20 text-over-image-container sm:mt-28 md:mt-32 xl:mt-40 w-12/12">
						<img className="w-full m-auto sm:w-6/12" src="/keret_2 1.svg" />
						<h1 className="m-auto font-black text-center text-white uppercase text-overlay text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-shadow calibri-bold text-bold" dangerouslySetInnerHTML={{ __html: t.make_a_pledge_and_play }}></h1>
					</div>

					<TiktokPrize></TiktokPrize>
					<InstagramPrize></InstagramPrize>
					<FacebookPrize></FacebookPrize>
				</div>
			</div>
		</Fragment>);
}

export default Contest;
