import Winners from './Winners.jsx';
import React, { Fragment, useEffect, useState, useRef } from "react";
import Button from '../Utils/Button';
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';

function FacebookPrize() {

	const dates = [
		"2024.06.17. 11:00:00",
		"2024.06.20. 11:00:00",
		"2024.06.24. 11:00:00",
	];

	const [opened, setOpened] = useState(false);

	const t = useTranslation(lang);

	const openDetails = () => {
		setOpened(!opened);
	}

	return (
		<>

			<div className="container max-w-screen-xl px-10 mx-auto">

				{/* Headline */}
				<h2 className="w-full mt-20 text-xl sm:text-3xl font-bold text-center uppercase text-shadow calibri-bold">{t.facebook_headline}</h2>

				{/* Line */}
				<img className="mx-auto" src="/line 4.svg" alt="line" />

				{/* Paragraph */}
				<h1 className="w-full my-3 text-center text-lg sm:text-2xl font-bold text-shadow calibri-bold uppercase">{t.facebook_paragraph}</h1>

				{/* Glowing rectangle */}
				<div className="mx-auto bg-transparent-white backdrop-blur-md border-2 md:border-4 border-solid rounded-2xl border-[#00FFAE] shadow-around shadow-[#00FFAE] mt-12">

					<div className="flex flex-col gap-4 p-8 md:p-12 lg:flex-row">

						{/* Hotdog cola */}
						<div className="flex flex-col items-center justify-center px-4 image">
							<img className="w-3/4 sm:w-1/2 lg:w-fit max-w-[300px]" src="/fb_prize/2 HOTDOG _ COLA_02 1.png" alt="Hotdog" />
						</div>


						{/* Content */}
						<div className="flex flex-col items-center justify-center px-0 py-8 lg:px-8 lg:py-0 content">

							{/* Description */}
							<p className="text-lg text-center lg:text-2xl" dangerouslySetInnerHTML={{ __html: t.facebook_description }}></p>

							{/* Period */}
							<p className="mt-8 text-lg text-center lg:text-2xl" dangerouslySetInnerHTML={{ __html: t.facebook_period }}></p>

							{/* Buttons */}
							<div className="flex flex-col items-center justify-between w-full gap-4 px-0 mt-12 lg:px-4 lg:flex-row">

								<Button theme="secondary" classes="svg-black-hover" onClick={() => setOpened(opened => !opened)}>
									<span className="text-base lg:text-xl pt-1 uppercase calibri-bold">{t.game_rules}</span>
									<svg className="ml-2" width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M1.80371 1.55204L7.45679 7.50266L13.1099 1.55204" stroke="white" stroke-width="1.98356" stroke-linecap="round" />
									</svg>
								</Button>

								<a target="_blank" href="https://www.facebook.com/mol.magyarorszag/" rel="noreferrer">
									<Button theme="primary">
										<span className="text-base lg:text-xl pt-1 uppercase calibri-bold">{t.play}</span>
									</Button>
								</a>
							</div>


							{/* Game rules */}
							<div className={`game-rules transition-all duration-1000 h-full ${opened ? 'max-h-[4000px] opacity-100' : 'max-h-0 opacity-0'}`}>
								<div className={`flex flex-col w-full p-8 px-8 mt-12 border-2 border-white rounded-2xl bg-glass`}>

									{/* First game */}
									<div className="flex justify-between gap-4">
										<div className="flex items-center">
											<h3 className="text-xl text-center lg:text-3xl" dangerouslySetInnerHTML={{ __html: t.facebook_first_game_period }}></h3>
										</div>

										<a href="fb_prize/docs/MOL_FB_Fogadalom1_Játékszabályzat_0612.pdf" target="_blank">
											<Button theme="secondary"><span className="mt-1 text-lg uppercase calibri-bold">{t.game_rules}</span></Button>
										</a>
									</div>

									{/* Second game */}
									<div className="flex justify-between gap-4 mt-8">
										<div className="flex items-center">
											<h3 className="text-xl text-center lg:text-3xl" dangerouslySetInnerHTML={{ __html: t.facebook_second_game_period }}></h3>
										</div>

										<a href="fb_prize/docs/MOL_FB_Fogadalom2_Játékszabályzat_0617.pdf" target="_blank">
											<Button theme="secondary"><span className="mt-1 text-lg uppercase calibri-bold">{t.game_rules}</span></Button>
										</a>
									</div>

									{/* Third game */}
									<div className="flex justify-between gap-4 mt-8">
										<div className="flex items-center">
											<h3 className="text-xl text-center lg:text-3xl" dangerouslySetInnerHTML={{ __html: t.facebook_third_game_period }}></h3>
										</div>

										<a href="fb_prize/docs/MOL_FB_Fogadalom3_Játékszabályzat_0620.pdf" target="_blank">
											<Button theme="secondary"><span className="mt-1 text-lg uppercase calibri-bold">{t.game_rules}</span></Button>
										</a>
									</div>

								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<Winners contestName="facebook" dates={dates}></Winners>

		</>
	)
}

export default FacebookPrize;
