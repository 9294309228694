import Footer from './components/Footer.jsx';
import Navbar from './components/Navbar.jsx';
import Home from './components/Home.jsx';
import Contest from './components/Contest.jsx';
import CookieNotice from './components/CookieNotice.jsx';
import Administrator from './components/Administrator.jsx';
import React, { Component, useEffect } from "react";
import { I18nProvider, browser, ignorePlaceCode, useLocaleSetting } from '@plaidev/react-i18n';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
} from "react-router-dom";

function App() {

  return (
    <I18nProvider
      fallbackLocale={localStorage.getItem('locale') || 'hu'}
      detectors={[browser, ignorePlaceCode]}
    >
      <Router>
        <Navbar></Navbar>
        <Routes>
          <Route
            path="/"
            element={<Home />}
          ></Route>
          <Route
            path="/nyeremenyjatek"
            element={<Contest />}
          ></Route>
          <Route
            path="/suti-tajekoztato"
            element={<CookieNotice />}
          ></Route>
        </Routes>
        <Footer></Footer>
      </Router>
    </I18nProvider>
  );
}

export default App;
