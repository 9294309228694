import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation, useLocaleSettingValue } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';
import Winner from "./Winner.jsx";
import moment from "moment";
import 'moment/min/locales'

function Winners({ contestName, dates }) {

	const { locale } = useLocaleSettingValue();

	const t = useTranslation(lang);

	return (
		<Fragment>

			<div className="container max-w-screen-xl xl:w-1/2 px-10 mx-auto">
				{/* Border */}
				<div className="bg-transparent border-1 md:border-2 border-solid border-white shadow-in-and-around shadow-[#00FFAE] my-20 h-fit">
					<div className="p-5 md:p-24 relative m-auto">  {/* border-[#00FFAE] relative shadow-around shadow-[#00FFAE] */}
						<h1 className="mx-auto font-black text-center text-white uppercase m-auto text-xl sm:text-3xl text-shadow calibri-bold">
							{t.date_of_the_draw}:
							<br />
							{dates.map((date, index) => {
								const parsedDate = moment(date, "YYYY.MM.DD. hh:mm:ss");
								const jackpotText = contestName == 'tiktok' || contestName == 'instagram' && index == dates.length - 1 ? t.jackpot : '';
								const formattedDate = parsedDate.locale(locale).format(`YYYY. MMMM DD. hh ${locale == 'en' ? 'A' : '[óra]'}`); 

								return (
									<span key={index} className="neon-green">
										<Winner filename={`${contestName}-winners-${parsedDate.format("MMDD")}.json`} date={jackpotText + formattedDate}></Winner>
									</span>
								);
							})}
						</h1>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default Winners;
