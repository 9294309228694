import Header from './Home/Header.jsx';
import Prizes from './Home/Prizes.jsx';
import Social from './Home/Social.jsx';
import Video from './Home/Video.jsx';
import React, { Fragment, useRef, useState, useMemo, useEffect } from "react";
import { useTranslation, useLocaleSettingValue } from "@plaidev/react-i18n";
import { lang } from '../localisation.js';

function Home() {

	const t = useTranslation(lang);

	const { locale } = useLocaleSettingValue();

	const [prizesOpened, setPrizesOpened] = useState(true);

	const openPrizes = () => {
		setPrizesOpened(!prizesOpened);
	}

	const [oathOpened, setOathOpened] = useState(true);

	const openOath = () => {
		setOathOpened(!oathOpened);
	}


	/*** Video In Viewport Check START ***/
	// const [videoIsInViewport, setVideoIsInViewport] = useState(false);
	const videoRef = useRef(null);

	useEffect(() => {

		const handleScroll = () => {
			const video = document.querySelector('#video-iframe')
			if (video) {
				const targetPosition = video.getBoundingClientRect().top + window.scrollY;
				const scrollPosition = window.scrollY;

				if (scrollPosition + window.innerHeight - 300 > targetPosition) {
					setOathOpened(false)
					setPrizesOpened(false)
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	/*** Video In Viewport Check END ***/

	return (
		<Fragment>
			<div className="relative overflow-hidden bg-black app">

				{/* Background */}
				<div className="absolute top-[-18rem] min-[400px]:top-[-15.5rem] min-[500px]:top-[-10.5rem] min-[600px]:top-[-8rem] sm:top-[-15rem] md:top-[-12rem] lg:top-[-9rem] xl:top-[-2rem] 2xl:top-[-15.5rem] min-[1950px]:top-[-24rem] min-[2100px]:top-[-35rem] min-[2300px]:top-[-45rem] min-[2500px]:top-[-55rem] min-[2700px]:top-[-65rem] min-[2900px]:top-[-75rem] min-[3100px]:top-[-85rem] min-[3300px]:top-[-95rem] min-[3500px]:top-[-105rem] min-[3700px]:top-[-115rem] min-[3900px]:top-[-125rem] left-0 w-screen h-full bg-no-repeat bg-cover" style={{ backgroundImage: 'url("/app-background-low.jpg")' }}></div>
				<img src="/gradiens.svg" className="absolute bottom-0 left-0 z-10 w-screen h-auto bg-no-repeat bg-cover" />

				{/* Make a pledge */}
				<div className={`fixed w-[11rem] sm:w-[15rem] md:w-[18rem] lg:w-[21.1rem] transition-all duration-500 ease-in-out right-[1rem] md:right-[3rem] lg:right-[5rem] xl:right-[5rem] 2xl:right-[10rem] top-[12rem] lg:inset-y-1/4 z-50 h-fit`} >
					<div className="text-over-image-container">
						<img className="w-full" src="/Rectangle 19.svg" />
						<div className="flex flex-row items-center w-full text-overlay justify-evenly">
							<img onClick={openOath} className={`hidden w-2/12 mx-1 md:w-2/12 md:mx-5 transition ease-in-out duration-500 cursor-pointer ${oathOpened ? '-rotate-90' : 'rotate-90'}`} src="/fb_prize/jatek idopontjai gomb nyil.svg" />
							<a className="p-4 lg:p-2" href="/nyeremenyjatek">
								<h2 className="calibri-bold max-[460px]:text-[2vw] text-[0.6rem] sm:text-sm md:text-base xl:text-lg 2xl:text-xl text-center text-shadow" dangerouslySetInnerHTML={{ __html: t.make_a_pledge_for }}></h2>
							</a>
						</div>
					</div>
				</div>

				{/* Open prizes */}
				{/* <div onClick={openPrizes} className={`fixed z-50 inset-y-1/3 lg:inset-y-2/4 transition-all duration-500 ease-in-out cursor-pointer ${prizesOpened ? 'right-0' : 'right-[-10rem] xl:right-[-15rem]'}`}>
					<div className="flex flex-row items-center w-fit rounded-l-3xl bg-transparent-white justify-evenly">
						<div className="flex flex-[0_0_4rem] min-w-0 justify-center items-center">
							<div className="text-base -rotate-90 nowrap calibri-bold xl:text-3xl">{t.prizes}</div>
						</div>
						<div className={`flex-auto`}>
							<img className="w-1/2 md:w-fit" src="/nyeremenyek.svg" />
						</div>
					</div>
				</div> */}

				{/* Prizes */}
				<div className={`fixed right-0 z-50 inset-y-1/2 cursor-pointer scale-75 origin-right md:scale-100`} onClick={openPrizes}>

					{/* Backround */}
					<div className="flex rounded-l-3xl bg-transparent-white h-[256px] backdrop-blur">
						<div className="flex items-center justify-center max-w-[64px]">
							<div className="text-xl -rotate-90 nowrap calibri-bold xl:text-3xl text-shadow">{t.prizes}</div>
						</div>
						<div className={`flex w-full h-full transition-all duration-500 ${prizesOpened ? 'max-w-[250px]' : 'max-w-0'}`}>
							<img className="w-full" src="/nyeremenyek.svg" alt="Nyeremények" />
						</div>
					</div>
				</div>

				{/*
				<div onClick={openPrizes} className={`w-1/2 md:w-fit fixed cursor-pointer ${prizesOpened ? 'right-[-2rem] sm:right-[-60px] md:right-[-1rem] xl:right-[-2.5rem]' : 'right-[-8.5rem] min-[400px]:right-[-10rem] min-[500px]:right-[-13rem] min-[600px]:right-[-16rem] sm:right-[-225px] md:right-[-18rem] xl:right-[-18.5rem]'} rounded-t-3xl pt-2 sm:pt-3 md:pt-4 px-1 md:px-10 xl:px-20 bg-transparent-white transition-all ease-in-out duration-500 -rotate-90 top-[14rem] sm:top-[25rem] md:top-[25rem] z-50 text-xl md:text-3xl calibri-bold`}>
						<div className="text-center">{t.prizes}</div>
						<img className="w-1/2 m-auto rotate-90 md:w-fit" src="/nyeremenyek.svg" />
				</div>
				*/}
				{/* Header smoke effect
      <div className="absolute left-0 w-1/2 h-screen !bg-left-top !bg-no-repeat !bg-cover opacity-100 mix-blend-exclusion" style={{ background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%), url("/header-effect-left.png") no-repeat' }}></div>
      <div className="absolute right-0 w-1/2 h-screen !bg-right-top !bg-no-repeat !bg-cover opacity-100 mix-blend-exclusion" style={{ background: 'linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%), url("/header-effect-right.png") no-repeat' }}></div>
 */}

				<div className="relative z-10 content">
					<Header></Header>
					<Video ref={videoRef}></Video>
					<Social></Social>
				</div>
			</div>
		</Fragment>);
}

export default Home
