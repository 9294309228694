import React, { useEffect, useState } from "react";
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';

function Winner({ filename, date }) {

	const t = useTranslation(lang);

	const [opened, setOpened] = useState(false);

	const openDetails = () => {
		setOpened(!opened);
	}

	const [winners, setWinners] = useState([]);
	useEffect(() => {
		fetch(`/${filename}`)
			.then(response => response.json())
			.then(response => {
				setWinners(response);
			})
			.catch((error) => {
				setWinners([]);
			});
	}, []);

	return (
		<div>
			<button onClick={openDetails} className="w-full flex flex-row items-center justify-center cursor-pointer py-2 duration-300 transition-all hover:text-white">
				<span className="text-xl sm:text-3xl" dangerouslySetInnerHTML={{ __html: date }}></span>
				<svg className={`ml-2 transition-all duration-300 ease-in-out ${opened ? 'rotate-180' : ''}`} width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.80371 1.55204L7.45679 7.50266L13.1099 1.55204" stroke="white" stroke-width="1.98356" stroke-linecap="round" />
				</svg>
			</button>
			<div className={`text-white text-lg sm:text-2xl transition-all duration-500 ease-in-out h-full ${opened ? 'max-h-[4000px] opacity-1' : 'max-h-0 opacity-0'}`}>
				{winners.length > 0 && winners[0] != "" ? (
					<div className={`${opened ? 'max-h-[4000px] opacity-1' : 'max-h-0 opacity-0'}`}>
						{t.winners}:
						<ul className={`list-disc text-center w-full text-base sm:text-xl calibri-bold ${opened ? 'max-h-[4000px] opacity-1' : 'max-h-0 opacity-0'}`}>
							{winners.map((winner, index) => (
								<li key={index} className={`w-2/12 whitespace-nowrap m-auto text-left ${opened ? 'max-h-[4000px] opacity-1' : 'max-h-0 opacity-0'}`}>
									{winner}
								</li>
							))}
						</ul>
					</div>
				) : (
					<div className={`text-base sm:text-xl ${opened ? 'max-h-[4000px] opacity-1' : 'max-h-0 opacity-0'}`}>{t.no_winners_yet}</div>
				)}
			</div>
		</div>
	);
}

export default Winner;
