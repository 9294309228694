export const lang = {
	en: {
		'prizes': 'Prizes',
		'make_a_pledge_and_play': 'Make a pledge <br />and play for the prizes!',

		'tiktok_headline': 'Tiktok competition',
		'tiktok_paragraph': 'Do you want to win a PlayStation 5 gaming console? Here’s how you can enter the contest!',
		'tiktok_description': `<b class="calibri-bold">„If there will be a fourth, then…”</b> - how would you continue the sentence? Make a pledge for the success of the national team in your own TikTok video, tag the <b class="calibri-bold">@mol.magyarorszag</b> channel, use the <b class="calibri-bold">#MOLpledge</b> hashtag, and win our main prize: <b class="calibri-bold">a PlayStation 5 game console!</b>`,
		'tiktok_period': `Contest period: <b class="calibri-bold">June 3, 2024 - June 23, 2024.</b>`,


		'instagram_headline': 'Instagram competition',
		'instagram_paragraph': 'If you’re interested in winning the HISENSE 55A6K 4K UHD Smart LED TV, here’s what you need to do:',
		'instagram_description': `Download our <b class="calibri-bold">Instagram Story</b> template before the group matches from the <b class="calibri-bold">MOL Hungary Instagram
page</b>, share your personal pledge on your public Instagram profile using the template, and play for
fan jerseys or the <b class="calibri-bold">HISENSE 55A6K 4K UHD Smart LED TV</b>! When uploading your story, don&#39;t forget to
tag the <b class="calibri-bold">@mol.magyarorszag</b> page and use the <b class="calibri-bold">#MOLpledge</b> hashtag!`,
		'instagram_first_game_period': 'First contest period:<br/> <b class="calibri-bold">2024.06.12. - 2024.06.15.</b>',
		'instagram_second_game_period': 'Second contest period:<br/> <b class="calibri-bold">2024.06.17. - 2024.06.19.</b>',
		'instagram_third_game_period': 'Third contest period:<br/> <b class="calibri-bold">2024.06.20. - 2024.06.23.</b>',


		'facebook_headline': 'Facebook competition',
		'facebook_paragraph': 'Comment and win a Fresh Corner fan snack package!',
		'facebook_description': `Follow the <b class="calibri-bold">MOL Facebook</b> page, comment and win! Before each Hungarian group match, we will share a post where we look forward to seeing your supportive pledges in the comments. Among the submitters, we raffle off <b class="calibri-bold">Fresh Corner snack package</b> coupons redeemable in the <b class="calibri-bold">MOL Move app</b>!`,
		'facebook_first_game_period': 'First contest period:<br/> <b class="calibri-bold">2024.06.12. - 2024.06.15.</b>',
		'facebook_second_game_period': 'Second contest period:<br/> <b class="calibri-bold">2024.06.17. - 2024.06.19.</b>',
		'facebook_third_game_period': 'Third contest period:<br/> <b class="calibri-bold">2024.06.20. - 2024.06.23.</b>',

		'jackpot': 'Jackpot:<br />',
		'no_winners_yet': 'There was no draw yet',
		'date_of_the_draw': 'Dates(s) of the draw',
		'winners': 'Winners',
		'game_rules': 'Game rules',
		'play': 'Play',
		'we_believe_in_you': 'We believe in you more than ever before.',
		'make_a_pledge_for': 'Make a pledge for the success of the national team and <br/><b class="max-[460px]:text-[3vw] text-[2.2vw] sm:text-[2.5vw] md:text-3xl 2xl:text-3xl">WIN!</b>',
		'its_been_a_while': 'It’s been a while since the Hungarian national team had such a promising opportunity for success.<br /> Let’s show Marco Rossi’s team that even a small nation can have <br />a big voice, and that with enough faith, no goal is unattainable!',
		'fan_is_within': 'The fan within.',
		'more_than_voice': 'Let&#39;s do more than just voice our support: let&#39;s demonstrate it through actions for the national team!',
		'use_the_hashtag': 'Use the <b class="calibri-bold">#MOLpledge</b> hashtag on <b class="calibri-bold">TikTok</b>, <b class="calibri-bold">Instagram</b> and the <b class="calibri-bold">MOL Facebook page</b>,<br /> make a pledge for the success of the team, and win valuable gifts!',
		'make_a_pledge': 'Make a pledge',
		'they_expressed_support': 'Those who have already expressed their support for the team:',
		'prize_game': 'Prize game',
		'cookie_informant': 'Cookie information',
		'mol_hungary_all_rights_reserved': 'MOL Hungary - All rights reserved! © 2024',
		'terms_of_use': 'Terms of Use',
		'data_protection': 'Data protection',
	},
	hu: {
		'prizes': 'Nyeremények',
		'make_a_pledge_and_play': 'Tegyél fogadalmat <br />és játssz a nyereményekért!',
		'game_rules': 'Játékszabályzat',
		'play': 'Játszom',
		'winners': 'Nyertesek',
		'date_of_the_draw': 'A sorsolás időpontja(i)',
		'no_winners_yet': 'Még nem volt sorsolás',
		'jackpot': 'Főnyeremény:<br />',

		'tiktok_headline': 'Tiktok nyereményjáték',
		'tiktok_paragraph': 'Hazavinnéd a PlayStation 5 játékkonzolt? Így tudsz nevezni rá!',
		'tiktok_description': `<b class="calibri-bold">„Ha lesz negyedik, akkor…”</b> – és te hogyan folytatnád a mondatot? Tegyél te is fogadalmat a válogatott sikereiért saját TikTok-videódban, jelöld be a 
<b class="calibri-bold">@mol.magyarorszag</b> csatornáját, használd a <b class="calibri-bold">#MOLfogadalom</b> hashtaget, és nyerd meg fődíjunkat:<b class="calibri-bold"> egy Playstation 5 játékkonzolt!</b>`,
		'tiktok_period': `Játék időtartama: <b class="calibri-bold">2024.06.03. - 2024. 06.23.</b>`,

		'instagram_headline': 'Instagram nyereményjáték',
		'instagram_paragraph': 'Ha megnyernéd a HISENSE 55A6K 4K UHD Smart LED televíziót, akkor az alábbiakat kell tenned:',
		'instagram_description': `<b class="calibri-bold">Használd az Instagram Story-sablonunkat</b> a csoportmérkőzések előtt a <b class="calibri-bold">MOL Magyarország Instagram-oldalán</b>, oszd meg a személyes fogadalmadat nyilvános Instagram-profilodon a sablon segítségével, és játssz a <b class="calibri-bold">szurkolói mezekért</b> vagy a <b class="calibri-bold">4K-s okos TV</b>-ért! A történeted feltöltésekor ne felejtsd el bejelölni a <b class="calibri-bold">@mol.magyarorszag</b> oldalt és használni a <b class="calibri-bold">#MOLfogadalom</b> hashtaget!`,
		'instagram_first_game_period': 'Első játék időtartama:<br/> <b class="calibri-bold">2024.06.12. - 2024.06.15.</b>',
		'instagram_second_game_period': 'Második játék időtartama:<br/> <b class="calibri-bold">2024.06.17. - 2024.06.19.</b>',
		'instagram_third_game_period': 'Harmadik játék időtartama:<br/> <b class="calibri-bold">2024.06.20. - 2024.06.23.</b>',


		'facebook_headline': 'Facebook nyereményjáték',
		'facebook_paragraph': 'Kommentelj és nyerj Fresh Corner szurkolói nasicsomagot!',
		'facebook_description': 'Kövesd figyelemmel a <b class="calibri-bold">MOL Facebook-oldalát, kommentelj</b> és <b class="calibri-bold">nyerj</b>! Minden magyar csoportmérkőzés előtt kiteszünk egy posztot, amely alá tőletek várjuk kommentben a <b class="calibri-bold">támogató fogadalmakat</b>. A beküldők között a <b class="calibri-bold">MOL Move applikációban</b> beváltható <b class="calibri-bold">Fresh Corner nasicsomag-kuponokat</b> sorsolunk ki!',
		'facebook_first_game_period': 'Első játék időtartama:<br/> <b class="calibri-bold">2024.06.12. - 2024.06.15.</b>',
		'facebook_second_game_period': 'Második játék időtartama:<br/> <b class="calibri-bold">2024.06.17. - 2024.06.19.</b>',
		'facebook_third_game_period': 'Harmadik játék időtartama:<br/> <b class="calibri-bold">2024.06.20. - 2024.06.23.</b>',

		'we_believe_in_you': 'Jobban hiszünk <br /> bennetek, mint valaha.',
		'make_a_pledge_for': 'Tegyél te is fogadalmat a válogatott sikereiért és <br/><b class="max-[460px]:text-[3vw] text-[2.2vw] sm:text-[2.5vw] md:text-3xl 2xl:text-3xl">NYERJ!</b>',
		'its_been_a_while': 'A magyar válogatottnak régóta nem volt ekkora esélye a sikerre.<br /> Mutassuk meg Marco Rossi csapatának, hogy egy kis nemzetnek is lehet <br />nagy hangja, és kellő hittel semmi sem lehetetlen!',
		'fan_is_within': 'A szurkoló benned van.',
		'more_than_voice': 'Ne csak szavakkal fejezzük ki támogatásunkat, álljunk ki tettekkel is a Nemzeti Tizenegy mellett!',
		'use_the_hashtag': 'Használd a <b class="calibri-bold">#MOLfogadalom</b> hashtaget <b class="calibri-bold">Instagramon</b>, <b class="calibri-bold">TikTokon</b> és a <b class="calibri">MOL Facebook oldalán</b>,<br /> tegyél te is fogadalmat a válogatott sikereiért és nyerj értékes ajándékokat!',
		'make_a_pledge': 'Fogadalmat teszek',
		'they_expressed_support': 'Ők már kiálltak a válogatott mellett:',
		'prize_game': 'Nyereményjáték',
		'cookie_informant': 'Cookie tájékoztató',
		'mol_hungary_all_rights_reserved': 'MOL Magyarország - Minden jog fenntartva! © 2024',
		'terms_of_use': 'Felhasználási feltételek',
		'data_protection': 'Adatvédelem',
	}
}
