import { lang } from '../localisation.js';
import {
	useTranslation,
	useLocaleSetting,
	useLocaleSettingValue,
	useSetLocaleSetting,
} from '@plaidev/react-i18n';
import { useState, useEffect } from 'react';

export default function Navbar() {

	const t = useTranslation(lang);

	const [{ locale }, { setLocale }] = useLocaleSetting();

	const changeLang = (l) => {
		localStorage.setItem('locale', l);
		setLocale(l);
	}

	useEffect(() => {
		const currentLocale = localStorage.getItem('locale')
		if (currentLocale) {
			setLocale(currentLocale)
		}
	}, [])

	return (
		<nav className="fixed z-50 w-full px-4 py-[1.15rem] bg-white border-gray-200">
			<div className="relative flex flex-wrap items-center justify-between">

				{/* Logo */}
				<a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
					<img src="/logo.svg" className="absolute left-0 h-12 top-1 lg:left-14 md:left-6 sm:left-3 sm:h-16 md:h-20 lg:h-24" alt="Mol Logo" />
				</a>

				<div className="container w-full max-w-screen-sm mx-auto xl:max-w-screen-md 2xl:max-w-screen-lg" id="navbar-default">
					<ul className="flex flex-row items-center justify-end w-full p-0 mt-0 space-x-8 font-medium rounded-lg lg:justify-between">
						<li className="inline">
							<a href="/nyeremenyjatek" className="text-sm uppercase align-text-top transition-all duration-300 rounded calibri-bold sm:text-lg text-primary mol-red" aria-current="page">{t.prize_game}</a>
						</li>
						<li className="flex items-center">
							<a href="/suti-tajekoztato" className="mr-4 text-sm align-text-top transition-all duration-300 rounded calibri-bold sm:text-lg text-primary hover:text-primaryHover" aria-current="page">{t.cookie_informant}</a>

							{/* Flag */}
							<div className="cursor-pointer flag w-[32px] h-[24px] bg-center bg-cover" style={{ backgroundImage: `url(language_flags/${locale === 'en' ? 'hu' : 'en'}.svg)` }} onClick={() => changeLang(locale === 'en' ? 'hu' : 'en')}></div>
						</li>
					</ ul>
				</div>
			</div>
		</nav>

	);
}
