import { forwardRef, Fragment } from "react"

const Video = forwardRef((props, ref) => {

	return (
		<Fragment>
			<section id="video-iframe" ref={ref} className="container w-full px-4 mx-auto sm:w-10/12 md:w-9/12 max-w-screen-2xl video">
				<div className="w-full m-auto overflow-hidden md:w-11/12 rounded-2xl aspect-video">
					<iframe className="w-full h-full" src="https://www.youtube.com/embed/MSt0bnwZsug?si=Od2A8JTs0u4Fi43F" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
				</div>
			</section>
		</Fragment>
	);
});

export default Video;
