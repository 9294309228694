

function CookieNotice() {

	const showCookiePopup = () => {
		window.showPopUp();
	}

	return (
		<>

			<div className="bg-primary pt-40 pb-20">
				<div className="container m-auto rounded-xl bg-white text-gray-900 calibri h-full">
					<div className="p-10">
						<h1 className="text-4xl calibri-light">Süti (cookie) tájékoztató</h1>
						<br />

						<p className="calibri-bold">A mol.hu/fogadalom weboldalon használt cookiek</p>
						<br />

						<p>A mol.hu/fogadalom weboldal bizonyos területein használunk sütiket (cookiekat).</p>
						<br />

						<p>A süti (angolul „cookie”) egy kis szövegfájl a weboldal forráskódjában (a kiszolgáló és felhasználó között cserélt kisméretű adat). A sütiket a
							felhasználó az eszközökén (pl. számítógép, laptop, okostelefon) használt böngészővel tölti le, és az magában a böngészőben tárolódik. A böngésző
							az adott weboldalhoz tartozó sütiket elküldi a POMS Cloud Ltd. számára. A POMS Cloud Ltd. a felhasználó eszközéhez nem fér hozzá.
						</p>
						<br />

						<p>
							A süti típusa lehet munkamenet süti, vagy állandó süti.
						</p>
						<br />

						<p>
							Az oldal működése során az Ön nyelvi beállításait a böngésző localStorage tárolójában mentjük el annak érdekében, hogy felhasználói élményét javítsuk. Ezek az adatok nem tartalmaznak személyes információkat és csak a nyelvi beállítások megőrzésére használjuk.
						</p>
						<br />

						<p>
							A sütit elhelyező fél szempontjából a süti lehet saját süti, vagy harmadik féltől származó süti.
						</p>
						<br />

						<p>
							<b className="calibri-bold">Egyes harmadik fél sütik esetén a személyes adatokat az Európai Gazdasági Térség (EGT) területén kívülre továbbítják</b>: Google LLC,
							Meta Platforms Inc. („Meta”, korábban Facebook), Xandr Inc. A legtöbb EGT-n kívüli ország nem biztosít a GDPR V. fejezete alapján elvárt, az
							EGT-n belüli adatvédelemnek megfelelő védelmi szintet. A szolgáltatók ebben az esetben az Európai Bizottság által kidolgozott általános
							adattovábbítási mintaszerződéseket (standard contractual clauses, „<b className="calibri-bold">SCC</b>”) használják.
						</p>
						<br />

						<p>
							A mol.hu/fogadalom weboldalon használt cookie-k a <a className="text-primary" target="_blank" href="/docs/Süti (cookie) tájékoztató.pdf">cookie tájékoztatóban</a> találhatóak.
						</p>
						<br />

						<p>
							A sütibeállításokat az alábbi gombra való kattintással bármikor megváltoztathatja.
						</p>
						<br />

						<p className="text-center">
							<button onClick={showCookiePopup} className="rounded-md calibri-bold uppercase bg-primary hover:bg-primaryHover text-white p-2 px-10">
								Sütibeállítások kezelése
							</button>
						</p>

					</div>
				</div>
			</div>
		</>
	)
}

export default CookieNotice;
