import React, { useState } from 'react';
import { useTranslation } from "@plaidev/react-i18n";
import { lang } from '../../localisation.js';

export default function Header() {

	const t = useTranslation(lang);

	return (
		<section className="header">
			<div className="w-screen relative flex flex-col items-center justify-center">

				<div className="container">
					<div className="text-over-image-container mt-28">
						<img className="w-8/12 sm:w-1/2 xl:w-7/12 2xl:w-1/2 m-auto" src="/keret_1 1.svg" alt="players" />
						<h1 className="w-8/12 sm:w-1/2 m-auto text-overlay text-center text-white uppercase text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl text-shadow calibri-bold" dangerouslySetInnerHTML={{ __html: t.we_believe_in_you }}></h1>
					</div>
					{/*
					<h2 className="mt-12 text-center text-white mb-28 text-p text-shadow calibri-bold">
						A magyar válogatottnak régóta nem volt ekkora esélye a sikerre.<br />
						Mutassuk meg Marco Rossi csapatának, hogy egy kis nemzetnek is lehet <br />nagy hangja, és kellő hittel semmi sem lehetetlen!
					</h2>
					*/}
				</div>

				<div className='container'>
					<img className="w-full sm:w-8/12 xl:w-9/12 2xl:w-8/12 mt-5 m-auto" src="/focistak_kerettel 1.svg" alt="players" />
					<a href="#video" id="video">
						<img className="w-1/12 sm:w-auto mb-10 mt-2 m-auto" src="/down-button.svg" alt="down-button" />
					</a>
				</div>

			</div>
		</section>
	)
}
