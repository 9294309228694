export default function Button(props) {

	const style = props.theme === 'secondary' ? 'border-2 border-white hover:bg-white text-white hover:text-black' : 'bg-primary hover:bg-primaryHover'

	return (
		<button className={`flex items-center text-center justify-center duration-300 transition-all px-6 py-4 text-lg lg:text-2xl rounded-2xl min-w-[140px] lg:min-w-[200px] ${style} ${props.classes}`} {...props}>
			{props.children}
		</button>
	)
}
